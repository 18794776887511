import Axios from "axios";

export default class HealthProfileService {
  static getHealthProfile = async (controller) => {
    let response = null;
    await Axios({
      crossDomain: true,
      dataType: "json",
      method: "post",
      url: `${process.env.REACT_APP_BASE_URL_SEGUROS_UNIMED_HAZO}v2/getHealthProfileData/`,
      data: JSON.stringify({
        controller: controller
      })
    })
      .then((res) => {
        response = res.data;
      })
      .catch(() => {
        response = false;
      });

    return response;
  };
}
