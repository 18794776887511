import "./style.css";
import pdfPage1 from "../../assets/img/Relatorio-Perfil-Saude-Cover-Page-1.jpg";

export default function Cover(props) {
  return (
    <div className="container-cover">
      <div className="image-container">
        <img src={pdfPage1} alt="Page cover" />
        <h1>
          Olá,
          <br />
          {props.name}!
        </h1>
      </div>
    </div>
  );
}
