import "./style.css";
import pdfPage14 from "../../assets/img/Relatorio-Perfil-Saude-SaudeEmocionalI-Page-14.jpg";

export default function Page14() {
  return (
    <div>
      <img src={pdfPage14} alt={`Page 14 report Health Profile`} />
      <a
        className="box-footer-url-page14"
        href={"https://www.segurosunimed.com.br/saude-emocional"}
        target="_blank"
        rel="noreferrer"
        aria-hidden="true"
      >
        .
      </a>
    </div>
  );
}
