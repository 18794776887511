import "./style.css";
import pdfPage17 from "../../assets/img/Relatorio-Perfil-Saude-ExamesPreventivosCancerColorretalI-Page-17.jpg";

export default function Page17() {
  return (
    <div>
      <img src={pdfPage17} alt={`Page 17 report Health Profile`} />
      <a
        className="box-footer-url-page17"
        href={
          "https://midias.segurosunimed.com.br/content/SU-FD-CP-cacolorretal.pdf"
        }
        target="_blank"
        rel="noreferrer"
        aria-hidden="true"
      >
        .
      </a>
    </div>
  );
}
