import "./style.css";
import pdfPage15 from "../../assets/img/Relatorio-Perfil-Saude-SaudeEmocionalII-Page-15.jpg";

export default function Page15() {
  return (
    <div>
      <img src={pdfPage15} alt={`Page 15 report Health Profile`} />
      <a
        className="box-footer-url-page15"
        href={"https://www.segurosunimed.com.br/saude-emocional"}
        target="_blank"
        rel="noreferrer"
        aria-hidden="true"
      />
    </div>
  );
}
