import { Routes, Route } from "react-router-dom";
import HealthProfile from "../page/HealthProfile";

export default function AllRoutes() {
  return (
    <Routes>
      <Route exact path="/:controller" element={<HealthProfile />} />
    </Routes>
  );
}
