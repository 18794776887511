import "./style.css";
import pdfPage20 from "../../assets/img/Relatorio-Perfil-Saude-ExamesPreventivosCancerMamaII-Page-20.jpg";

export default function Page20() {
  return (
    <div>
      <img src={pdfPage20} alt={`Page 20 report Health Profile`} />
      <a
        className="box-footer-url-page20"
        href={
          "https://midias.segurosunimed.com.br/content/CP-Outubro_Rosa_2021.pdf"
        }
        target="_blank"
        rel="noreferrer"
        aria-hidden="true"
      >
        .
      </a>
    </div>
  );
}
