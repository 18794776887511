import "./style.css";
import pdfPage6 from "../../assets/img/Relatorio-Perfil-Saude-AtividadeFisica-Page-6.jpg";

export default function Page6() {
  return (
    <div>
      <img src={pdfPage6} alt={`Page 6 report Health Profile`} />
      <a
        className="box-footer-url-page6"
        href={
          "https://midias.segurosunimed.com.br/content/Atividade-Fisica-DIGITAL.pdf"
        }
        target="_blank"
        rel="noreferrer"
        aria-hidden="true"
      >
        .
      </a>
    </div>
  );
}
