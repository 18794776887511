import "./style.css";
import pdfPage13 from "../../assets/img/Relatorio-Perfil-Saude-Tabagismo-Page-13.jpg";

export default function Page13() {
  return (
    <div>
      <img src={pdfPage13} alt={`Page 13 report Health Profile`} />
      <a
        className="box-footer-url-page13"
        href={"https://www.segurosunimed.com.br/cuidando-de-perto/tabagismo"}
        target="_blank"
        rel="noreferrer"
        aria-hidden="true"
      >
        .
      </a>
    </div>
  );
}
