import "./style.css";
import pdfPage16 from "../../assets/img/Relatorio-Perfil-Saude-ExamesPreventivosCancerColoDoUtero-Page-16.jpg";

export default function Page16() {
  return (
    <div>
      <img src={pdfPage16} alt={`Page 16 report Health Profile`} />
      <a
        className="box-footer-url-page16"
        href={
          "https://midias.segurosunimed.com.br/content/SU-FD-CP-CA_colo.pdf"
        }
        target="_blank"
        rel="noreferrer"
        aria-hidden="true"
      >
        .
      </a>
    </div>
  );
}
