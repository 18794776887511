import "./style.css";
import pdfPage2 from "../../assets/img/Relatorio-Perfil-Saude-Gestante-Page-2.jpg";

export default function Page2() {
  return (
    <div>
      <img src={pdfPage2} alt={`Page 2 report Health Profile`} />
      <a
        className="box-footer-url-page2"
        href={
          "https://www.segurosunimed.com.br/cuidando-de-perto/parto-adequado"
        }
        target="_blank"
        rel="noreferrer"
        aria-hidden="true"
      >
        .
      </a>
    </div>
  );
}
