import "./style.css";
import pdfPage4 from "../../assets/img/Relatorio-Perfil-Saude-AlimentacaoSaudavel-Page-4.jpg";

export default function Page4() {
  return (
    <div>
      <img src={pdfPage4} alt={`Page 4 report Health Profile`} />
      <a
        className="box-footer-url-page4"
        href={
          "https://midias.segurosunimed.com.br/content/Reeducacao-Alimentar-Lamina-Digital.pdf"
        }
        target="_blank"
        rel="noreferrer"
        aria-hidden="true"
      >
        .
      </a>
    </div>
  );
}
