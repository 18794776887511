import "./style.css";
import pdfPage18 from "../../assets/img/Relatorio-Perfil-Saude-ExamesPreventivosCancerColorretalII-Page-18.jpg";

export default function Page18() {
  return (
    <div>
      <img src={pdfPage18} alt={`Page 18 report Health Profile`} />
      <a
        className="box-footer-url-page18"
        href={
          "https://midias.segurosunimed.com.br/content/SU-FD-CP-cacolorretal.pdf"
        }
        target="_blank"
        rel="noreferrer"
        aria-hidden="true"
      >
        .
      </a>
    </div>
  );
}
