import "./style.css";
import pdfPage12 from "../../assets/img/Relatorio-Perfil-Saude-PesoCorporalObesidadeIII-Page-12.jpg";

export default function Page12() {
  return (
    <div>
      <img src={pdfPage12} alt={`Page 12 report Health Profile`} />
      <a
        className="box-body-url-page12"
        href={
          "https://midias.segurosunimed.com.br/content/SU-CP-FD-Obesidade.pdf"
        }
        target="_blank"
        rel="noreferrer"
        aria-hidden="true"
      >
        .
      </a>
    </div>
  );
}
