import "./style.css";
import pdfPage11 from "../../assets/img/Relatorio-Perfil-Saude-PesoCorporalObesidadeII-Page-11.jpg";

export default function Page11() {
  return (
    <div>
      <img src={pdfPage11} alt={`Page 11 report Health Profile`} />
      <a
        className="box-body-url-page11"
        href={
          "https://midias.segurosunimed.com.br/content/SU-CP-FD-Obesidade.pdf"
        }
        target="_blank"
        rel="noreferrer"
        aria-hidden="true"
      >
        .
      </a>
    </div>
  );
}
