import "./style.css";
import { useEffect, useState, createRef } from "react";
import { useParams } from "react-router-dom";
import HealthProfileService from "../../services/HealthProfileService";
import Page1 from "../../components/Page1";
import Page2 from "../../components/Page2";
import Page3 from "../../components/Page3";
import Page4 from "../../components/Page4";
import Page5 from "../../components/Page5";
import Page6 from "../../components/Page6";
import Page7 from "../../components/Page7";
import Page8 from "../../components/Page8";
import Page9 from "../../components/Page9";
import Page10 from "../../components/Page10";
import Page11 from "../../components/Page11";
import Page12 from "../../components/Page12";
import Page13 from "../../components/Page13";
import Page14 from "../../components/Page14";
import Page15 from "../../components/Page15";
import Page16 from "../../components/Page16";
import Page17 from "../../components/Page17";
import Page18 from "../../components/Page18";
import Page19 from "../../components/Page19";
import Page20 from "../../components/Page20";
import Page21 from "../../components/Page21";
import ReactToPdf from "react-to-pdf";

export default function HealthProfile() {
  const { controller } = useParams();
  const [arrayAnswers, setArrayAnswers] = useState(null);
  const [loading, setLoading] = useState(true);
  const [optionsPdf, setOptionsPdf] = useState({});
  const [pages, setPages] = useState(null);
  const [userData, setUserData] = useState(null);
  const arrayWithNumberPages = [1, 4, 6, 21]; // Pages statics
  const ref = createRef();

  useEffect(() => {
    const getAnswersFromChatbotHealthProfile = async () => {
      const response = await HealthProfileService.getHealthProfile(controller);

      if (response) {
        setArrayAnswers(response.data.answerData);
        setUserData(response.data.userData);
      } else {
        // Render Page Not Found
      }
    };

    if (!arrayAnswers || !userData) {
      getAnswersFromChatbotHealthProfile();
    }
    // eslint-disable-next-line
  }, [arrayAnswers, userData]);

  useEffect(() => {
    if (arrayAnswers !== null && userData !== null) {
      assembleReportHealthProfile();
      setLoading(false);
    }
    // eslint-disable-next-line
  }, [arrayAnswers, userData]);

  function assembleReportHealthProfile() {
    // answers about question of IMC
    let heightIMC = 0;
    let weightIMC = 0;

    // answers about question of PHQ-2
    let answer28 = "";
    let answer29 = "";
    let answer32 = "";
    let answer57 = "";

    // answers about question of Chronic Diseases
    let answer35 = "";
    let answer75 = "";

    // This mapping was based on dictionary on VPSHAZO: /var/www/sites/hazo.me/modelos/1868/ENnXbQ/dicionario.php
    arrayAnswers.map((answer) => {
      // Question about pregnant
      if (answer.question === "q_06" && answer.answer === "Sim") {
        arrayWithNumberPages.push(2);
      }

      // Set answers about IMC
      if (answer.question === "q_08") {
        heightIMC = Number(
          answer.answer.substring(0, 1) + "." + answer.answer.substring(1, 3)
        );
      }
      if (answer.question === "q_10") {
        weightIMC = Number(answer.answer.replace(",", "."));
      }

      // Question about smoking
      if (answer.question === "q_12" && answer.answer === "Sim") {
        arrayWithNumberPages.push(13);
      }

      // Question about alcoholism
      if (
        answer.question === "q_18" &&
        answer.answer !== "Nenhuma das opções anteriores"
      ) {
        arrayWithNumberPages.push(5);
      }

      // Set answers about PHQ-2
      if (answer.question === "q_28") {
        answer28 = answer.answer;
      }

      if (answer.question === "q_29") {
        answer29 = answer.answer;
      }

      if (answer.question === "q_32") {
        answer32 = answer.answer;
      }

      if (answer.question === "q_57") {
        answer57 = answer.answer;
      }

      // Set answers about chronic diseases
      if (answer.question === "q_35") {
        answer35 = answer.answer;
      }

      if (answer.question === "q_75") {
        answer75 = answer.answer;
      }

      return null;
    });

    // Every women from age between 25 and 75, receive pages 16, 19 and 20
    if (
      userData.sexo.toLowerCase().substring(0, 1) === "f" &&
      userData.idade > 24 &&
      userData.idade < 76
    ) {
      arrayWithNumberPages.push(16);
      arrayWithNumberPages.push(19);
      arrayWithNumberPages.push(20);
    }

    // Every women or men from age between 50 and 75, receive pages 17 and 18
    if (
      (userData.sexo.toLowerCase().substring(0, 1) === "f" ||
        userData.sexo.toLowerCase().substring(0, 1) === "m") &&
      userData.idade > 49 &&
      userData.idade < 76
    ) {
      arrayWithNumberPages.push(17);
      arrayWithNumberPages.push(18);
    }

    calculateIMC(weightIMC, heightIMC);
    calculatePHQ2(answer28, answer29, answer32, answer57);
    chronicDiseases(answer35, answer75);
    setPages(<div>{returnPages(arrayWithNumberPages)}</div>);
  }

  function calculateIMC(weight, height) {
    let imc = 0;
    let pageNumberToPush = null;
    imc = weight / (height * height);

    if (imc < 18.5 && imc !== 0) {
      pageNumberToPush = 7;
    } else if (imc >= 18.5 && imc <= 24.9) {
      pageNumberToPush = 8;
    } else if (imc >= 25 && imc <= 29.9) {
      pageNumberToPush = 9;
    } else if (imc >= 30 && imc <= 34.9) {
      pageNumberToPush = 10;
    } else if (imc >= 35 && imc <= 39.9) {
      pageNumberToPush = 11;
    } else if (imc >= 40) {
      pageNumberToPush = 12;
    }

    if (arrayWithNumberPages.indexOf(pageNumberToPush) === -1) {
      arrayWithNumberPages.push(pageNumberToPush);
    }
  }

  function calculatePHQ2(
    answerQuestion28 = "",
    answerQuestion29 = "",
    answerQuestion32 = "",
    answerQuestion57 = ""
  ) {
    let pageNumberToPush = null;
    const pontuationQuestion28 =
      convertAnswerToPontuationPHQ2(answerQuestion28);
    const pontuationQuestion29 =
      convertAnswerToPontuationPHQ2(answerQuestion29);
    const pontuationQuestion32 =
      convertAnswerToPontuationPHQ2(answerQuestion32);
    const pontuationQuestion57 =
      convertAnswerToPontuationPHQ2(answerQuestion57);

    // calculation based on pontuation PHQ-2
    if (
      pontuationQuestion28 + pontuationQuestion29 >= 3 ||
      pontuationQuestion32 + pontuationQuestion57 >= 3
    ) {
      pageNumberToPush = 14;
    }

    if (arrayWithNumberPages.indexOf(pageNumberToPush) === -1) {
      arrayWithNumberPages.push(pageNumberToPush);
    }
  }

  function chronicDiseases(asnwerQuestion35, asnwerQuestion75) {
    let pageNumberToPush = null;
    if (
      asnwerQuestion35 !== "Nenhuma das opções anteriores" ||
      asnwerQuestion75 === "Sim"
    ) {
      pageNumberToPush = 3;
    }

    if (arrayWithNumberPages.indexOf(pageNumberToPush) === -1) {
      arrayWithNumberPages.push(pageNumberToPush);
    }
  }

  // The following function will sort the array in ascending order (if it doesn't contain Infinity and NaN)
  function compareNumbers(a, b) {
    return a - b;
  }

  function convertAnswerToPontuationPHQ2(answer) {
    if (answer === "Nunca") return 0;
    if (answer === "Em vários dias") return 1;
    if (answer === "Em mais da metade do número de dias") return 2;
    if (answer === "Em quase todos os dias") return 3;
    if (
      answer === "" ||
      (answer !== "Nunca" &&
        answer !== "Em vários dias" &&
        answer !== "Em mais da metade do número de dias" &&
        answer !== "Em quase todos os dias")
    )
      return 0;
  }

  function convertNumberToReferPage(number) {
    switch (number) {
      case 1:
        return (
          <Page1
            name={
              arrayAnswers[1].question === "q_66" ? arrayAnswers[1].answer : ""
            }
          />
        );
      case 2:
        return <Page2 />;
      case 3:
        return <Page3 />;
      case 4:
        return <Page4 />;
      case 5:
        return <Page5 />;
      case 6:
        return <Page6 />;
      case 7:
        return <Page7 />;
      case 8:
        return <Page8 />;
      case 9:
        return <Page9 />;
      case 10:
        return <Page10 />;
      case 11:
        return <Page11 />;
      case 12:
        return <Page12 />;
      case 13:
        return <Page13 />;
      case 14:
        return <Page14 />;
      case 15:
        return <Page15 />;
      case 16:
        return <Page16 />;
      case 17:
        return <Page17 />;
      case 18:
        return <Page18 />;
      case 19:
        return <Page19 />;
      case 20:
        return <Page20 />;
      case 21:
        return <Page21 />;
      default:
        return null;
    }
  }

  async function generatePdf(toPdf) {
    await handleSetOptionsPdf();
    toPdf();
  }

  function getWindowDimensions() {
    const { innerWidth: width, outerHeight: height } = window;
    return {
      width,
      height
    };
  }

  async function handleSetOptionsPdf() {
    let width = getWindowDimensions().width - 13; // 13 is about lenght of scroll bar
    let height = document.getElementById("general-page").scrollHeight;

    setOptionsPdf({
      unit: "px",
      hotfixes: ["px_scaling"],
      format: [width, height > width ? height : width + 1]
    });
  }

  function returnPages(array) {
    return array.sort(compareNumbers).map((numberPage, index) => {
      return (
        <div className="container-image" key={index}>
          {convertNumberToReferPage(numberPage)}
        </div>
      );
    });
  }

  if (!loading) {
    return (
      <div>
        <ReactToPdf
          filename={`relatorio_perfil_saude.pdf`}
          options={optionsPdf}
          targetRef={ref}
          x={1}
          y={1}
          scale={1}
        >
          {({ toPdf }) => (
            <div className="container-button">
              {/* <button onClick={async () => await generatePdf(toPdf)}>
                Para imprimir o seu relatório
              </button> */}
            </div>
          )}
        </ReactToPdf>
        <div id="general-page" ref={ref}>
          {pages}
        </div>
      </div>
    );
  } else {
    return <div>loading ...</div>;
  }
}
