import { BrowserRouter } from "react-router-dom";
import AllRoutes from "./router";
import RouterProvider from "./context";

function App() {
  return (
    <RouterProvider>
      <BrowserRouter>
        <AllRoutes />
      </BrowserRouter>
    </RouterProvider>
  );
}

export default App;
